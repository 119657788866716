// select2
@import "select2/dist/css/select2.css";
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';
// Bootstrap
@import 'bootstrap/scss/bootstrap';
//fontawesom
@import '@fortawesome/fontawesome-free/css/all.min.css';

.select2 {
    width: 100%!important; /* overrides computed width, 100px in your demo */
  }

label{
    font-weight: bold;
}
// ====== Signature =======
.wrapper {
    position: relative;
    width: 400px;
    height: 200px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.signature-pad {
    position: absolute;
    left: 0;
    top: 0;
    width: 400px;
    height: 200px;
}

.select2-container .select2-selection--single {
    height: 30px !important;
}

// form groups
.underline,
.underlines,
input#printName{
    border-bottom: 1px solid #ccc;
    padding: 0px 10px;
    text-align: center;
}
.underlines{
    margin-top: 10px;
    height: $height-15;
}
.w-15{
    width: $width-15;
}

// ordered-list-items-18px
ol.ml-4 > li {
    margin-left: 18px;
}
p{
    #hide{
        display:none;
        white-space:pre;
    }

    #input#prospective-employer-authorization,
    #hide{
        font:inherit;
        margin:0;
        padding:0;
    }

    .inline{
        display:inline !important;
        width: 800px !important;
    }

    input#prospective-employer-authorization{
        color:inherit;
        min-width:10px;

    }
}
.btn-default {
    background: #ccc;
    width: 100px;
}
.bb-1,
.marker-underline{
    border-bottom: 1px solid #ccc;
}

.no-border{
    border: 0;
}

.psp_declaration{
    .signature-pad{
        width: 500px !important;
    }
}

.form-group {
    span.marker-x{
        padding-top: 1vw;
    }
}

.all-border{
    border: 1px solid #ccc;
}


.swal2-popup span.selection {
    display: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.custom-add-padding,
.custom-add-padding:hover{
    text-decoration: none;
    color: #fff;
}



// responsive media
// @import 'media.scss';
